import React, {useMemo, useState, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'

import {Avatar, CircularProgress, Typography} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import {CrossIcon} from '../../../../icons'

import {Wrapper} from './MembersPageStyle'
import {STATUSES_IN} from '../../../MyRodcomesPage/constants/myRodcomesPageConstants'
import {committeeSettingsSelectors} from '../../../../store/committeeSettingsStore/committeeSettingsStore'
import TableComponent from './components/TableComponent/TableComponent'
import {profileSelectors} from '../../../../store/profileStore/profileStore'
import {APP_STATES} from '../constants/parentCommitteeConstants'
import MembersHeaderComponent from './components/MembersHeader/MembersHeaderComponent'
import {creatingRkSelectors} from '../../../../store/creatingRkStore/creatingRkStore'
import Invite from '../Summary/components/Invite/Invite'
import {makeRkMembersEvent} from '../../../../store/reportingStore/reportingStoreSliceEvents'
import {Box} from '@mui/system'
import MemberRecordPage from '../../subpages/Members/components/MemberRecordPage/MemberRecordPage'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {Dialog, Button} from '@mui/material'
import {avatarURL} from '../../../../utils/imageURL'
import {
  approveMembershipEvent,
  rejectMembershipEvent
} from '../../../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import DocumentsPage from '../Documents/DocumentsPage'

const MembersPage = () => {
  const roles = useSelector(committeeSettingsSelectors.roles.selector)
  const members = useSelector(committeeSettingsSelectors.membersParentCommittee.selector)
  const isLoading = useSelector(committeeSettingsSelectors.membersIsLoading.selector)
  const appMembership = useSelector(committeeSettingsSelectors.appMembership.selector)
  const recordsMembers = useSelector(committeeSettingsSelectors.recordsMembers.selector)
  const rkUuid = useSelector(committeeSettingsSelectors.uuid.selector)
  const user = useSelector(profileSelectors.user.selector)
  const loading = useSelector(creatingRkSelectors.isLoading.selector)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const isChief = useMemo(() => roles?.includes(STATUSES_IN.Chief), [roles])
  const [openModal, setOpenModal] = useState(false)
  const [memberModal, setMemberModal] = useState(false)
  const [currentMember, setCurrentMember] = useState({})
  const [memberId, setMemberId] = useState('')

  const filteredApps = useMemo(() => appMembership.filter(({state}) => state === APP_STATES.New), [appMembership])

  const data = useMemo(() => {
    const mappedAppMembership = filteredApps.map((item) => ({...item, userUuid: item?.uuid, fromApplication: true}))
    const mappedMembers = members?.map((item) => {
      return {...item, isMember: true, isCurrentUser: user?.uuid === item?.userUuid}
    })
    return [...mappedMembers, ...mappedAppMembership, ...recordsMembers]
  }, [members, user, filteredApps, recordsMembers])

  const membLength = members?.length || 0

  const handleDownloadClick = () => {
    dispatch(makeRkMembersEvent.action(rkUuid))
  }

  const handleAddRecordClick = () => {
    navigate(`${location.pathname}/member-record`)
  }

  const handleModal = (userUuid, value) => {
    setOpenModal(value)
    setMemberId(userUuid)
  }

  const openMemberModal = (e) => {
    setCurrentMember(e)
    setMemberModal(true)
  }

  const closeMemberModal = () => {
    setMemberModal(false)
    setCurrentMember({})
  }

  const handleApproveMembership = useCallback((userUuid) => {
    dispatch(approveMembershipEvent.action(userUuid))
    setCurrentMember({})
    setMemberModal(false)
  }, [])

  const handleRejectMembership = useCallback((userUuid) => {
    dispatch(rejectMembershipEvent.action(userUuid))
    setCurrentMember({})
    setMemberModal(false)
  }, [])

  const pattern = new RegExp(`(^\\d{${11 % 10}})(\\d{3})(\\d{3})(\\d{2})(\\d{2}$)`)
  console.log(currentMember)

  return (
    <>
      <Dialog open={openModal} onClose={() => setOpenModal(false)} fullScreen={fullScreen} fullWidth maxWidth="xs">
        <MemberRecordPage setOpenModal={setOpenModal} memberId={memberId} />
      </Dialog>
      <Dialog
        open={memberModal}
        onClose={closeMemberModal}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xs"
        sx={{margin: '12px'}}
      >
        <Box sx={{padding: '24px', display: 'flex', flexDirection: 'column', gap: '24px'}}>
          <IconButton
            sx={{
              position: 'absolute',
              top: '8px',
              left: 'auto',
              right: '12px'
            }}
            onClick={closeMemberModal}
          >
            <CrossIcon />
          </IconButton>
          <Box sx={{display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center'}}>
            <Avatar
              alt={`${currentMember?.fullName?.firstName}`}
              src={avatarURL(currentMember?.fullName?.photo)}
              sx={{width: '64px', height: '64px'}}
            />
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
              <Typography
                variant="caption"
                sx={{fontSize: '24px', fontWeight: 400, lineHeight: '36px', color: '#39215E'}}
              >
                {`${currentMember?.fullName?.surname} ${currentMember?.fullName?.firstName}`}
              </Typography>
              <Typography
                variant="caption"
                sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: '#39215E'}}
              >
                {currentMember?.role === 'Educator' ? 'Педагог' : 'Участник'}
              </Typography>
            </Box>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
            {currentMember?.verifiedPhone ? (
              <Typography
                variant="caption"
                sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: '#39215E'}}
              >
                {currentMember?.verifiedPhone.replace(pattern, '+7 ($2) $3-$4-$5')}
              </Typography>
            ) : null}
            <Typography
              variant="caption"
              sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: '#39215E'}}
            >
              {currentMember?.email}
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center'}}>
              {currentMember?.children?.map((e) => (
                <>
                  <Avatar
                    alt={`${e?.fullName?.firstName}`}
                    src={avatarURL(e?.fullName?.photo)}
                    sx={{width: '40px', height: '40px'}}
                  />
                  <Typography
                    variant="caption"
                    sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: '#39215E'}}
                  >
                    {`${e?.fullName?.surname} ${e?.fullName?.firstName}`}
                  </Typography>
                </>
              ))}
            </Box>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Button
              color="info"
              variant="contained"
              sx={{width: '30%'}}
              onClick={() => handleRejectMembership(currentMember.userUuid)}
            >
              Отклонить
            </Button>
            <Button
              variant="contained"
              sx={{width: '30%'}}
              onClick={() => handleApproveMembership(currentMember.userUuid)}
            >
              Принять
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Box sx={{marginTop: '24px'}}>
        <Invite />
      </Box>
      {data.filter((e) => e.fromApplication)?.length ? (
        <Box
          sx={{
            padding: '38px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            background: '#FFFFFF',
            borderRadius: '12px',
            border: '1px solid #EDEDFF'
          }}
        >
          <Typography variant="caption" sx={{fontSize: '18px', fontWeight: 700, lineHeight: '32px', color: '#39215E'}}>
            Хотят вступить
          </Typography>
          <Box sx={{display: 'flex', flexDirection: {lg: 'row', sm: 'column'}, gap: '16px'}}>
            {data
              .filter((e) => e.fromApplication)
              .map((e) => (
                <Box
                  sx={{
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    gap: '8px',
                    borderRadius: '12px',
                    background: '#F5F5FF'
                  }}
                >
                  <Box sx={{display: 'flex', flexDirection: 'row', gap: '12px', alignItems: 'center'}}>
                    <Avatar alt={`${e?.fullName.firstName}`} src={avatarURL(e?.fullName?.photo)} />
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                      <Typography
                        variant="caption"
                        sx={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: '#39215E'}}
                      >
                        {`${e?.fullName?.surname} ${e?.fullName?.firstName}`}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{fontSize: '12px', fontWeight: 400, lineHeight: '16px', color: '#39215E'}}
                      >
                        {e?.email}
                      </Typography>
                    </Box>
                  </Box>
                  <Button color="info" variant="contained" onClick={() => openMemberModal(e)}>
                    Подробнее
                  </Button>
                </Box>
              ))}
          </Box>
        </Box>
      ) : null}
      <Wrapper>
        <MembersHeaderComponent
          membLength={membLength}
          referenceRecords={data?.length}
          handleAddRecordClick={handleAddRecordClick}
          // handleDownloadClick={handleDownloadClick}
          handleModal={handleModal}
          isChief={isChief}
        />
        {isLoading || loading ? (
          <CircularProgress sx={{margin: '0 auto', padding: 19, display: 'block'}} />
        ) : (
          <TableComponent data={data.filter((e) => !e.fromApplication)} isChief={isChief} handleModal={handleModal} />
        )}
      </Wrapper>
      <DocumentsPage />
    </>
  )
}

export default MembersPage
