import React, {useCallback, useEffect, useState, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  Wrapper,
  WrapperButton,
  WrapperChild,
  WrapperUserAgreement,
  RkDescription,
  InfoBlock
} from './JoiningRodcomStyle'
import {Box} from '@mui/material'
import YourRole from './components/YourRole/YourRole'
import YourRodcomDataForm from '../../components/YourRodcomDataForm/YourRodcomDataForm'
import {
  initYourRodcomDataErrors,
  initYourRodcomDataState
} from '../../components/YourRodcomDataForm/constants/YourRodcomDataFormConstants'
import SelectChildForm from '../../components/SelectChildForm/SelectChildForm'
import {childrenSelectors} from '../../store/childStore/childStore'
import {profileSelectors} from '../../store/profileStore/profileStore'
import {directoriesSelectors} from '../../store/directoriesStore/directoriesStore'

import UserAgreement from '../../components/UserAgreement/UserAgreement'
import {CheckIcon} from '../../icons'
import {HeaderWithBackButton} from '../../components/HeaderWithBackButton/HeaderWithBackButton'
import {checkEmail, checkIfPhoneValid, getNameError} from '../../utils/validations'
import {sendCurrentUserData} from '../../store/profileStore/profileStoreSliceEvents'
import {joiningRK} from '../../store/creatingRkStore/creatingRkStoreSliceEvents'
import {initAppSelectors} from '../../store/initStore/initStore'
import {EVENT_STATUSES} from '../LoginPage/constants'
import {PATHS} from '../../routes/paths'
import {setRequestStatus} from '../../store/initStore/initStoreSliceEvents'
import {creatingRkSelectors} from '../../store/creatingRkStore/creatingRkStore'
import {inviteSelectors} from '../../store/inviteStore/inviteStore'
import {StyledLoadingButton} from '../../components/StyledLoadingButton/StyledLoadingButton'
import {RK_ROLE} from './constants/index'
import {checkIfUserIsMemberEvent} from '../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {getProfileInfoEvent} from '../../store/inviteStore/inviteStoreSliceEvents'
import {CircularProgress} from '@mui/material'

const JoiningRodcom = ({withBank = true}) => {
  const {id} = useParams()

  const navigate = useNavigate()
  const {search} = useLocation()
  const dispatch = useDispatch()

  const params = new URLSearchParams(search)
  const companyName = params.get('name') || ''
  const companyDesc = params.get('desc') || ''
  const isLoading = useSelector(creatingRkSelectors.isLoading.selector)
  const requestStatus = useSelector(initAppSelectors.requestStatus.selector)
  const children = useSelector(childrenSelectors.children.selector)
  const user = useSelector(profileSelectors.user.selector)
  const inviteInfo = useSelector(inviteSelectors.inviteRk.selector)
  const classes = useSelector(directoriesSelectors.class.selector)
  const classesLetter = useSelector(directoriesSelectors.classLetter.selector)

  const [userAgreement, setUserAgreement] = useState(true)
  const [loading, setLoading] = useState(true)
  const [role, setRole] = useState(RK_ROLE.PARENT)
  const [isUserAgreementErr, setIsUserAgreementErr] = useState(false)
  const [yourRodcomErrors, setYourRodcomErrors] = useState(initYourRodcomDataErrors)
  const [childrenError, setChildrenError] = useState(false)
  const [yourRodcomFieldValues, setYourRodcomFieldValues] = useState(initYourRodcomDataState)
  const [checkedKids, setCheckedKids] = useState([])

  const handleOnChange = useCallback((value) => {
    setRole(value)
  }, [])

  // useEffect(() => {
  //   dispatch(checkIfUserIsMemberEvent.action(id))
  // }, [])

  useEffect(() => {
    dispatch(getProfileInfoEvent.action(id))
  }, [])

  useEffect(() => {
    if (requestStatus === EVENT_STATUSES.RK_MEMBERS_LIST) {
      const newPathname = location.pathname.replace(PATHS.JOINING_PARENT_COMMITTEE, PATHS.PARENT_COMMITTEE)
      navigate(newPathname)
      dispatch(setRequestStatus.action(''))
    }
    if (requestStatus === EVENT_STATUSES.RK_FAIL_NOT_FOUND_MEMBER || requestStatus === 'CHILDREN_LIST') {
      setLoading(false)
    }
    if (requestStatus === EVENT_STATUSES.RK_APPLICATION_MEMBERSHIP_CREATED) {
      navigate(PATHS.MY_PARENT_COMMITTEES)
      dispatch(setRequestStatus.action(''))
    }
  }, [requestStatus])

  useEffect(() => {
    setYourRodcomFieldValues({
      firstName: user?.fullName?.firstName ?? '',
      lastName: user?.fullName?.surname ?? '',
      middleName: user?.fullName?.patronymic ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      bank: user?.beneficiaryBankName || ''
    })
  }, [user])

  useEffect(() => {
    if (isUserAgreementErr) {
      setIsUserAgreementErr(!userAgreement)
    }
    if (childrenError) {
      setChildrenError(!checkedKids.length)
    }
  }, [checkedKids, userAgreement, isUserAgreementErr, childrenError])

  const rkInfoBlock = useMemo(() => {
    return (
      inviteInfo && (
        <RkDescription>
          {inviteInfo.classLetterUuid ? (
            <span>
              {inviteInfo.className}
              {classes[inviteInfo.classUuid]}
              {classesLetter[inviteInfo.classLetterUuid]}
            </span>
          ) : (
            <span>
              Группа: {inviteInfo.groupName} {inviteInfo.groupNumber}
            </span>
          )}
          <span>, &nbsp;{inviteInfo.institutionName}</span>
          <span>&nbsp;{inviteInfo.className && <q>{inviteInfo.className}</q>}</span>
          <div>Адрес: {inviteInfo.institutionAddress}</div>
        </RkDescription>
      )
    )
  }, [inviteInfo, classes, classesLetter])

  const handleUserAgreement = useCallback(() => {
    setUserAgreement(!userAgreement)
  }, [userAgreement])

  const setErrorField = (field, isError) => {
    setYourRodcomErrors((errs) => ({...errs, [field]: isError}))
  }

  const clearPhoneError = useCallback(() => {
    setErrorField('phone', false)
  }, [])

  const setFieldValue = ({value, field}) => {
    setYourRodcomFieldValues((values) => ({...values, [field]: value}))
  }

  const onChangeValue = useCallback(
    (value, field) => {
      const isError = yourRodcomErrors[field]
      if (isError) {
        setErrorField(field, false)
      }
      setFieldValue({value: value, field: field})
    },
    [yourRodcomErrors]
  )

  const onSubmit = useCallback(() => {
    const newErrors = {}
    newErrors.firstName = getNameError(yourRodcomFieldValues.firstName, 'Введите имя')
    newErrors.lastName = getNameError(yourRodcomFieldValues.lastName, 'Введите фамилию')
    newErrors.email = checkEmail(yourRodcomFieldValues.email)
    newErrors.phone = checkIfPhoneValid(yourRodcomFieldValues.phone) ? '' : 'Введите номер телефона'
    if (withBank) {
      newErrors.bank = getNameError(yourRodcomFieldValues.bank, 'Выберите банк')
    }

    setYourRodcomErrors(newErrors)
    setIsUserAgreementErr(!userAgreement)
    const childrErr = role === RK_ROLE.PARENT && !checkedKids.length
    setChildrenError(childrErr)
    const hasErrors = Object.values(newErrors).some((el) => el) || !userAgreement || childrErr
    if (hasErrors) {
      return
    }
    const userData = {
      uuid: user?.uuid,
      fullName: {
        firstName: yourRodcomFieldValues.firstName,
        surname: yourRodcomFieldValues.lastName,
        patronymic: yourRodcomFieldValues.middleName
      },
      email: yourRodcomFieldValues.email,
      phone: yourRodcomFieldValues.phone,
      userBank: user.beneficiaryBankName
    }

    dispatch(joiningRK.action({uuid: id, role, checkedKids}))
    dispatch(sendCurrentUserData.action({...user, ...userData}))
  }, [yourRodcomFieldValues, userAgreement, children, role, user, checkedKids])

  if (loading) {
    return <CircularProgress sx={{margin: '0 auto', padding: '150px', display: 'block'}} />
  }

  return (
    <Wrapper>
      <HeaderWithBackButton
        title="Вступление в родком"
        descriptionEl={rkInfoBlock}
        linkText="К учебному заведению"
        onClick={() => navigate(-1)}
      />
      <YourRole role={role} onChange={handleOnChange} />
      <InfoBlock>
        {role === RK_ROLE.PARENT && (
          <SelectChildForm
            setCheckedKids={setCheckedKids}
            showErr={childrenError}
            checkedKids={checkedKids}
            kids={children}
          />
        )}
        <YourRodcomDataForm
          withBank={withBank}
          clearPhoneError={clearPhoneError}
          errors={yourRodcomErrors}
          fieldValues={yourRodcomFieldValues}
          onChangeValue={onChangeValue}
        />
      </InfoBlock>
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <WrapperUserAgreement>
          <UserAgreement
            isError={isUserAgreementErr}
            userAgreement={userAgreement}
            handleUserAgreement={handleUserAgreement}
          />
          <StyledLoadingButton
            startIcon={<CheckIcon color="white" />}
            className="button"
            loading={isLoading}
            loadingPosition="start"
            variant="contained"
            type="button"
            onClick={onSubmit}
          >
            Вступить в родком
          </StyledLoadingButton>
        </WrapperUserAgreement>
      </Box>
    </Wrapper>
  )
}

export default JoiningRodcom
