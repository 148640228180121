import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  getMyAccrualsEvent,
  createBillEvent,
  cleanBillEvent,
  finishBillEvent
} from 'store/accrualsStore/accrualStoreSliceEvents'
import {useDispatch, useSelector} from 'react-redux'
import {setError} from '../../store/initStore/initStoreSliceEvents'
import {accrualsSelectors} from '../../store/accrualsStore/accrualStore'
import useScript from 'utils/useScript'

const PayPopup = ({onSuccess}) => {
  const dispatch = useDispatch()
  const payUrl = useSelector(accrualsSelectors.payUrl.selector)

  // useScript('https://oplata.qiwi.com/popup/v1.js')
  const onPaySuccess = useCallback((data) => {
    dispatch(finishBillEvent.action(data))

    setTimeout(() => {
      dispatch(cleanBillEvent.action())
    }, 30000)

    onSuccess(data)
  }, [])

  const onFail = useCallback((error) => {
    dispatch(setError.action('Ошибка оплаты'))
    dispatch(finishBillEvent.action())
    setTimeout(() => {
      dispatch(cleanBillEvent.action())
    }, 30000)
  }, [])

  const onClose = useCallback((error) => {
    dispatch(cleanBillEvent.action())
  }, [])

  useEffect(() => {
    if (!payUrl) return
    window.QiwiCheckout.openInvoice({
      payUrl: payUrl
    })
      .then((data) => {
        onPaySuccess(data)
      })
      .catch(({reason}) => {
        if (reason !== 'PAYMENT_FAILED') {
          onClose(reason)
        } else {
          onFail(reason)
        }
      })
  }, [payUrl])
  return <></>
}

export default PayPopup
