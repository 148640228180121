import {createSelectorsTree} from '../selectorsFactory'
import {generateReducersObject} from '../sliceMapping'
import {allInitAppEvents} from './accrualStoreSliceEvents'
// начисления
const initialState = {
  current: [],
  paid: [],
  all: [],
  summary: null,
  loading: false,
  payInProgress: false,
  payUrl: null,
  errors: {}
}

/** Reducers */
export const ACCRUAL_STORE = 'accrualStore'
export const accrualStore = generateReducersObject({
  allSliceEvents: allInitAppEvents,
  initialState,
  reducerName: ACCRUAL_STORE
})

/** Selectors */
export const accrualsSelectors = createSelectorsTree(initialState, ACCRUAL_STORE)
