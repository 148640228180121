import React, {useEffect, useMemo, useState, useCallback} from 'react'
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import {CircularProgress, Tabs} from '@mui/material'

import {PATHS} from '../../routes/paths'
import {
  getInitParentCommitteeData,
  getParentCommitteeOperationsEvent
} from '../../store/committeeSettingsStore/committeeSettingsStoreSliceEvents'
import {EVENT_STATUSES} from '../LoginPage/constants'
import {setRequestStatus} from '../../store/initStore/initStoreSliceEvents'
import {initAppSelectors} from '../../store/initStore/initStore'
import {committeeSettingsSelectors} from '../../store/committeeSettingsStore/committeeSettingsStore'
import {STATUSES_IN} from '../MyRodcomesPage/constants/myRodcomesPageConstants'
import {TabsWrapper, TabWrapper} from './ParentCommitteePageStyle'
import {getProjectsListEvent} from '../../store/projectsStore/projectsStoreSliceEvents'

const TABS = {
  SUMMARY: 'Сводка',
  PROJECTS: 'Проекты',
  REPORTS: 'Отчёты',
  VOTES: 'Голосования',
  MEMBERS: 'Участники',
  DOCUMENTS: 'Блог',
  SETTINGS: 'Настройки РК'
}

const ParentCommitteePage = () => {
  const {id: uuidParentCommittee} = useParams()
  const navigate = useNavigate()
  const locations = useLocation()
  const dispatch = useDispatch()

  const requestStatus = useSelector(initAppSelectors.requestStatus.selector)
  const tabsIsLoading = useSelector(committeeSettingsSelectors.tabsIsLoading.selector)
  const roles = useSelector(committeeSettingsSelectors.roles.selector)
  const members = useSelector(committeeSettingsSelectors.membersParentCommittee.selector)
  const rkId = useSelector(committeeSettingsSelectors.uuid.selector)

  const isChief = useMemo(() => roles?.includes(STATUSES_IN.Chief), [roles])

  const [activeTab, setActiveTab] = useState(TABS.SUMMARY)

  useEffect(() => {
    if (
      requestStatus === EVENT_STATUSES.RK_DELETED ||
      requestStatus === EVENT_STATUSES.RK_FAIL_NOT_FOUND ||
      requestStatus === EVENT_STATUSES.RK_FAIL_NOT_FOUND_MEMBER
    ) {
      navigate(PATHS.MY_PARENT_COMMITTEES)
      dispatch(setRequestStatus.action(''))
    }
    if (requestStatus === EVENT_STATUSES.RK_TRANSFER_CHIEF_MEMBERSHIP) {
      navigate(window?.location?.pathname?.replace('/assignment', ''))
      dispatch(setRequestStatus.action(''))
    }
  }, [requestStatus])

  useEffect(() => {
    dispatch(getInitParentCommitteeData.action(uuidParentCommittee))
    dispatch(getProjectsListEvent.action(uuidParentCommittee))
    uuidParentCommittee && dispatch(getParentCommitteeOperationsEvent.action(uuidParentCommittee, {}))
  }, [uuidParentCommittee])

  useEffect(() => {
    if (!uuidParentCommittee) {
      navigate(PATHS.MY_PARENT_COMMITTEES)
    }
    const split = locations.pathname.split('/')
    const tab = split[3]
    if (TABS?.[tab?.toUpperCase()]) {
      setActiveTab(TABS[tab?.toUpperCase()])
    } else {
      setActiveTab(TABS.SUMMARY)
    }
  }, [locations.pathname])

  const reopenSettings = useCallback(() => tabSwitchHandler(null, TABS.SETTINGS))
  const reopenProjects = useCallback(() => tabSwitchHandler(null, TABS.PROJECTS))
  const reopenSummary = useCallback(() => tabSwitchHandler(null, TABS.SUMMARY))
  const reopenVotes = useCallback(() => tabSwitchHandler(null, TABS.VOTES))
  const reopenMembers = useCallback(() => tabSwitchHandler(null, TABS.MEMBERS))

  const tabSwitchHandler = (event, newValue) => {
    switch (newValue) {
      case TABS.SUMMARY:
        navigate(PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_SUMMARY)
        break
      case TABS.PROJECTS:
        navigate(PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_PROJECTS)
        break
      case TABS.REPORTS:
        navigate(PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_REPORTS)
        break
      case TABS.VOTES:
        navigate(PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_VOTES)
        break
      case TABS.MEMBERS:
        navigate(PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_MEMBERS)
        break
      // case TABS.DOCUMENTS:
      //   navigate('https://rodcom.ru/how-to')
      //   break
      case TABS.SETTINGS:
        if (isChief) {
          navigate(PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_SETTINGS)
        } else {
          navigate(PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_SUMMARY)
        }
        break
      default:
        navigate(PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_SUMMARY)
        break
    }
    setActiveTab(newValue)
  }

  if (tabsIsLoading) {
    return <CircularProgress sx={{margin: '0 auto', padding: '120px', display: 'block'}} />
  }

  return (
    <>
      <TabsWrapper>
        <Tabs
          value={activeTab}
          onChange={tabSwitchHandler}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{style: {background: '#E1E1F2', height: '100%', borderRadius: 12}}}
        >
          <TabWrapper
            active={activeTab === TABS.SUMMARY ? 1 : 0}
            style={{zIndex: 1}}
            value={TABS.SUMMARY}
            label={TABS.SUMMARY}
            id={TABS.SUMMARY}
            component="a"
            onClick={(event) => {
              event.preventDefault()
              reopenSummary()
            }}
            href={PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_SUMMARY}
          />
          <TabWrapper
            active={activeTab === TABS.PROJECTS ? 1 : 0}
            value={TABS.PROJECTS}
            label={TABS.PROJECTS}
            style={{zIndex: 1}}
            id={TABS.PROJECTS}
            // onClick={reopenProjects}
            onClick={(event) => {
              event.preventDefault()
              reopenProjects()
            }}
            component="a"
            href={PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_PROJECTS}
          />
          <TabWrapper
            active={activeTab === TABS.REPORTS ? 1 : 0}
            value={TABS.REPORTS}
            label={TABS.REPORTS}
            style={{zIndex: 1}}
            id={TABS.REPORTS}
            onClick={(event) => {
              event.preventDefault()
              rkId && dispatch(getParentCommitteeOperationsEvent.action(rkId, {}))
            }}
            component="a"
            href={PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_REPORTS}
          />
          <TabWrapper
            active={activeTab === TABS.VOTES ? 1 : 0}
            value={TABS.VOTES}
            label={TABS.VOTES}
            style={{zIndex: 1}}
            id={TABS.VOTES}
            // onClick={reopenVotes}
            onClick={(event) => {
              event.preventDefault()
              reopenVotes()
            }}
            component="a"
            href={PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_VOTES}
          />
          <TabWrapper
            active={activeTab === TABS.MEMBERS ? 1 : 0}
            value={TABS.MEMBERS}
            label={`${TABS.MEMBERS} (${members.length})`}
            id={TABS.MEMBERS}
            style={{zIndex: 1}}
            // onClick={reopenMembers}
            onClick={(event) => {
              event.preventDefault()
              reopenMembers()
            }}
            component="a"
            href={PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_MEMBERS}
          />
          <TabWrapper
            active={activeTab === TABS.DOCUMENTS ? 1 : 0}
            value={TABS.DOCUMENTS}
            label={TABS.DOCUMENTS}
            id={TABS.DOCUMENTS}
            style={{zIndex: 1, minWidth: '0px'}}
            // onClick={(event) => {
            //   event.preventDefault()
            // }}
            component="a"
            target="_blank"
            href={'https://rodcom.ru/blog'}
          />
          {isChief && (
            <TabWrapper
              active={activeTab === TABS.SETTINGS ? 1 : 0}
              value={TABS.SETTINGS}
              label={TABS.SETTINGS}
              id={TABS.SETTINGS}
              style={{zIndex: 1}}
              component="a"
              onClick={(event) => {
                event.preventDefault()
              }}
              href={
                isChief
                  ? PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_SETTINGS
                  : PATHS.PARENT_COMMITTEE + '/' + uuidParentCommittee + PATHS.PARENT_COMMITTEE_SUMMARY
              }
            />
          )}
        </Tabs>
      </TabsWrapper>
      <Outlet />
    </>
  )
}

export default ParentCommitteePage
